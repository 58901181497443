import FormInput from "./FormInput";
import FormSelect from "./FormSelect";

export default function ShippingForm({ register, errors, companyToggle, isCompany }) {
  const countries = [
    { value: "" },
    { name: "Polska", value: "PL" },
    { value: "AT", name: "Austria" },
    { value: "BE", name: "Belgia" },
    { value: "BG", name: "Bułgaria" },
    { value: "HR", name: "Chorwacja" },
    { value: "CY", name: "Cypr" },
    { value: "CZ", name: "Czechy" },
    { value: "DK", name: "Dania" },
    { value: "EE", name: "Estonia" },
    { value: "FI", name: "Finlandia" },
    { value: "FR", name: "Francja" },
    { value: "GR", name: "Grecja" },
    { value: "ES", name: "Hiszpania" },
    { value: "IE", name: "Irlandia" },
    { value: "LT", name: "Litwa" },
    { value: "LU", name: "Luksemburg" },
    { value: "LV", name: "Łotwa" },
    { value: "MT", name: "Malta" },
    { value: "NL", name: "Holandia" },
    { value: "DE", name: "Niemcy" },
    { value: "PT", name: "Portugalia" },
    { value: "RO", name: "Rumunia" },
    { value: "SK", name: "Słowacja" },
    { value: "SI", name: "Słowenia" },
    { value: "SE", name: "Szwecja" },
    { value: "HU", name: "Węgry" },
    { value: "IT", name: "Włochy" },
  ];

  const firstName = { name: "shippingName", label: "Imię", options: "" };
  const lastName = { name: "shippingSurname", label: "Nazwisko", options: "" };
  const companyName = { name: "shippingCompanyName", label: "Nazwa Firmy", options: "" };
  const street = { name: "shippingStreet", label: "Ulica / Miejscowość", options: "" };
  const addressNumber1 = { name: "shippingAddressNumber1", label: "Numer budynku", options: "" };
  const addressNumber2 = { name: "shippingAddressNumber2", label: "Numer lokalu", options: "" };
  const zipCode = { name: "shippingZipCode", label: "Kod pocztowy", options: "" };
  const city = { name: "shippingCity", label: "Miasto / Poczta", options: "" };
  const country = { name: "shippingCountry", label: "Kraj", options: countries };
  const phone = { name: "shippingPhone", label: "Numer telefonu", options: "" };

  const countryChange = () => {
    console.log("set");
  };

  return (
    <div className="order_form">
      <div className="input__line">
        <div className="input__box">
          <FormInput
            controls={firstName}
            register={register}
          />
          <span className="input__err">{errors.shippingName?.message}</span>
        </div>
        <div className="input__box">
          <FormInput
            controls={lastName}
            register={register}
          />
          <span className="input__err">{errors.shippingSurname?.message}</span>
        </div>
      </div>
      {isCompany ? (
        <>
          <FormInput
            controls={companyName}
            register={register}
          />
          <span className="input__err">{errors.shippingCompanyName?.message}</span>
        </>
      ) : (
        <></>
      )}
      <FormInput
        controls={street}
        register={register}
      />
      <span className="input__err">{errors.shippingStreet?.message}</span>
      <div className="input__line">
        <div className="input__box">
          <FormInput
            controls={addressNumber1}
            register={register}
          />
          <span className="input__err">{errors.shippingAddressNumber1?.message}</span>
        </div>
        <div className="input__box">
          <FormInput
            controls={addressNumber2}
            register={register}
          />
          <span className="input__err">{errors.shippingAddressNumber2?.message}</span>
        </div>
      </div>
      <div className="input__line">
        <div className="input__box">
          <FormInput
            controls={zipCode}
            register={register}
          />
          <span className="input__err">{errors.shippingZipCode?.message}</span>
        </div>
        <div className="input__box">
          <FormInput
            controls={city}
            register={register}
          />
          <span className="input__err">{errors.shippingCity?.message}</span>
        </div>
      </div>
      <FormSelect
        controls={country}
        register={register}
        countryChange={countryChange}
      />
      <span className="input__err">{errors.shippingCountry?.message}</span>
      <FormInput
        controls={phone}
        register={register}
      />
      <span className="input__err">{errors.shippingPhone?.message}</span>
    </div>
  );
}
