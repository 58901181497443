import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import Axios from "axios";
import { InpostGeowidget } from "react-inpost-geowidget";

import { useCart } from "../../controllers/Cart";
import { useForm } from "react-hook-form";
import { useLanguage } from "../../controllers/Language";

import { apiUrl } from "../../Vars";

import BillingForm from "../../components/BillingForm";
import ShippingForm from "../../components/ShippingForm";

// import { placeOrder } from "../../controllers/Order";

import { defaultShema, shippingShema, companyShema, shippingCompanyShema } from "../../controllers/ValidationShemas";

export default function Order() {
  // const [ user, setUser ] = useState()
  const userCart = useCart();

  const Language = useLanguage();

  const [isCompany, setIsCompany] = useState(false);
  const [differentShipping, setDifferentShipping] = useState(false);
  const [shippingMethod, setShippingMethod] = useState("delivery");
  const [shippingCost, setShippingCost] = useState(20);
  const [orderValue, setOrderValue] = useState(0);
  const [productsValue, setProductsValue] = useState(0);
  const [combinedShema, setCombinedShema] = useState(defaultShema);
  const [point, setPoint] = useState(null);
  const [userCountry, setUserCountry] = useState("PL");
  const [idle, setIdle] = useState(false);
  const [active, setActive] = useState(true);

  const [searchParams] = useSearchParams();
  const cart_id = searchParams.get("cart_id");

  let discount = 0;

  let discountObject = JSON.parse(sessionStorage.getItem("discount"));
  if (discountObject) {
    discount = parseInt(discountObject.value) / 100;
    console.log(discount);
  } else {
    discountObject = {
      name: "none",
      value: 0,
    };
  }

  useEffect(() => {
    if (isCompany && differentShipping) {
      setCombinedShema(shippingCompanyShema);
    } else if (isCompany) {
      setCombinedShema(companyShema);
    } else if (differentShipping) {
      setCombinedShema(shippingShema);
    } else {
      setCombinedShema(defaultShema);
    }
  }, [isCompany, differentShipping]);

  const countryChange = (value) => {
    setUserCountry(value);
  };

  useEffect(() => {
    if (userCountry) {
      switch (userCountry) {
        case "PL":
          setShippingCost(22);
          break;
        case "GB":
          setShippingCost(145);
          break;
        default:
          setShippingCost(90);
          break;
      }
    }
  }, [userCountry]);

  const { register, handleSubmit, formState } = useForm({ resolver: zodResolver(combinedShema) });

  const { errors } = formState;

  useEffect(() => {
    let productsCost = 0;
    if (userCart) {
      userCart.map((item) => {
        let price = 0;
        if (userCountry !== "PL" && isCompany === true) {
          price = parseFloat(item.netto);
        } else if (userCountry !== "PL" && isCompany === true) {
          price = parseFloat(item.brutto);
        } else price = parseFloat(item.brutto);
        return (productsCost = productsCost + (price - price * discount) * item.quantity);
      });
    }
    setProductsValue(Math.round(productsCost * 100) / 100);
  }, [userCart, discount, userCountry, isCompany]);

  useEffect(() => {
    setOrderValue(productsValue + shippingCost);
  }, [shippingCost, productsValue]);

  const companyToggle = (e) => {
    setIsCompany((prevIsCompany) => !prevIsCompany);
  };

  const shippingToggle = (e) => {
    setDifferentShipping((prevDifferentShipping) => !prevDifferentShipping);
  };

  const shippingCostChange = (e) => {
    if (e.target.id === "pickup") {
      setShippingMethod("pickup");
      setShippingCost(0);
    } else if (e.target.id === "delivery") {
      setShippingMethod("delivery");
      setShippingCost(22);
    } else if (e.target.id === "box") {
      setShippingMethod("box");
      setShippingCost(19);
    }
  };

  // const submitOrder2 = (data) => {
  //   const orderObject = {
  //     // user: user,
  //     isCompany: isCompany,
  //     differentShipping: differentShipping,
  //     shippingMethod: shippingMethod,
  //     shippingCost: shippingCost,
  //     orderValue: orderValue,
  //     userCart: userCart,
  //     point: point,
  //     userCountry: userCountry,
  //     // taxNumber: taxNumber,
  //     cart_id: cart_id,
  //     discountObject: discountObject,
  //     setIdle: setIdle,
  //   };

  //   placeOrder(data, orderObject);
  // };

  useEffect(() => {
    console.log("1", shippingMethod, point, active);
    if (shippingMethod === "delivery") {
      setActive(true);
    } else if (shippingMethod === "box" && point !== null) {
      setActive(true);
    } else if (shippingMethod === "box" && point === null) {
      setActive(false);
    }
    console.log("2", shippingMethod, point, active);
  }, [shippingMethod, point, active]);

  const submitOrder = (data) => {
    // console.log(data)
    // taxValidation()
    setIdle(true);

    console.log(data);

    const orderInfo = data.orderInfo;

    const billingData = {
      email: data.email,
      repeatEmail: data.email,
      name: data.name,
      surname: data.surname,
      companyName: isCompany ? data.companyName : null,
      taxNumber: isCompany ? data.taxNumber : null,
      street: data.street,
      addressNumber1: data.addressNumber1,
      addressNumber2: data.addressNumber2,
      city: data.city,
      zipCode: data.zipCode,
      country: data.country,
      phone: data.phone,
    };

    const invoice = {
      type: "normal",
      type_of_sale: "",
      price_type: data.country === "PL" || isCompany === false ? "brutto" : "netto",
      currency: "PLN",
      paymentstate: "",
      paymentdate: "",
      alreadypaid_initial: "",
      paymentId: "",
      buyer_type: isCompany ? "company" : "personal",
      paymentMethod: "payU",
      discountName: discountObject.name,
      discountValue: discountObject.value,
      buyer_country: data.country,
    };

    if (point) {
      const shippingData = {
        name: differentShipping ? data.shippingName : data.name,
        surname: differentShipping ? data.shippingSurname : data.surname,
        companyName: differentShipping && isCompany ? data.shippingCompanyName : null,
        street: point.street,
        addressNumber1: point.building_number,
        addressNumber2: point.flat_number,
        pointName: point.name,
        city: point.city,
        zipCode: point.post_code,
        country: "pl",
        phone: differentShipping ? data.shippingPhone : data.phone,
      };
      createOrder(billingData, shippingData, orderInfo, invoice);
    } else {
      const shippingData = {
        name: differentShipping ? data.shippingName : data.name,
        surname: differentShipping ? data.shippingSurname : data.surname,
        companyName: differentShipping && isCompany ? data.shippingCompanyName : null,
        street: differentShipping ? data.shippingStreet : data.street,
        addressNumber1: differentShipping ? data.shippingAddressNumber1 : data.addressNumber1,
        addressNumber2: differentShipping ? data.shippingAddressNumber2 : data.addressNumber2,
        pointName: null,
        city: differentShipping ? data.shippingCity : data.city,
        zipCode: differentShipping ? data.shippingZipCode : data.zipCode,
        country: differentShipping ? data.shippingCountry : data.country,
        phone: differentShipping ? data.shippingPhone : data.phone,
      };
      createOrder(billingData, shippingData, orderInfo, invoice);
    }
  };

  // const randomUserId = parseInt(Math.random(10000, 100000)*1000000)

  const createOrder = (billingData, shippingData, orderInfo, invoice) => {
    const order = {
      userId: 99999,
      userType: "detal",
      userCountry: userCountry,
      userBillingData: billingData,
      userShippingData: shippingData,
      differentShipping: differentShipping,
      orderItems: userCart,
      orderValue: orderValue,
      shippingMethod: shippingMethod,
      shippingCost: shippingCost,
      userIsCompany: isCompany,
      paymentMethod: "payU",
      paymentStatus: "PENNDING",
      orderInfo: orderInfo,
      invoice: invoice,
      cart_id: cart_id,
      type: "DETAL",
    };

    console.log(order);

    Axios.post(`${apiUrl}/api/create-order-detal`, {
      order,
    })
      .then((response) => {
        localStorage.removeItem("userCart");
        setIdle(false);
        console.log(response);
        window.location.replace(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showError = (errors) => {
    console.log(errors);
  };

  const onPointSelect = (e) => {
    const pointObject = {
      name: e.name,
      street: e.address_details.street,
      building_number: e.address_details.building_number,
      flat_number: e.address_details.flat_number,
      post_code: e.address_details.post_code,
      city: e.address_details.city,
    };
    setPoint(pointObject);
  };

  return (
    <>
      <div className="order">
        <h2 className="title">Zamówienie</h2>
        <p className="undertitle">
          Prosimy o dokładne i poprawne wypełnienie formularza zamówienia oraz o wybór sposobu dostawy i metody płatności. Jeśli chcesz, aby towar został wysłany na inny adres niż dane rozliczeniowe, to zaznacz odpowiedni punkt i podaj dane do wysyłki. Po kliknięciu przycisku "Zamawiam i płacę"
          nastąpi przekierowanie na stronę operatora płatności PayU.
        </p>
        <br />
        <br />
        <div className="order__table">
          {/* left side --------------------------------------------------------------------------------- */}
          <div className="order__left">
            <h3>Dane do zamówienia</h3>
            <form
              action=""
              id="order_form"
              className="order__form"
              onSubmit={handleSubmit(submitOrder, showError)}
            >
              <BillingForm
                register={register}
                errors={errors}
                companyToggle={companyToggle}
                isCompany={isCompany}
                location={"orderPage"}
                countryChange={countryChange}
              />
              <br />
              {/* Shipping data ---------------------------------------------------------------------------- */}
              {shippingMethod === "box" ? (
                <></>
              ) : (
                <>
                  <div className="input__line__checkbox">
                    <input
                      type="checkbox"
                      name="additional_shipping"
                      id="additional_shipping"
                      value={differentShipping}
                      onChange={shippingToggle}
                    />
                    <label htmlFor="additional_shipping">Wysyłka na inny adres</label>
                  </div>
                  {differentShipping ? (
                    <ShippingForm
                      register={register}
                      errors={errors}
                      isCompany={isCompany}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </form>
          </div>
          {/* right side ------------------------------------------------------------------------------ */}
          <div className="order__right">
            {/* shipping methods ------------------------------------------------------------------ */}
            <div className="shipping">
              <h3>Wybierz formę dostawy</h3>
              <ul className="shipping__items">
                <li className="detail__item">
                  <span>
                    <input
                      type="radio"
                      name="shipping_method"
                      id="delivery"
                      checked={shippingMethod === "delivery"}
                      onChange={shippingCostChange}
                    />
                    <span>
                      <label htmlFor="delivery">Przesyłka kurierska</label>
                    </span>
                  </span>
                  <span className={`order__price ${Language}`}>22</span>
                </li>
                <li className="detail__item">
                  <span>
                    <input
                      type="radio"
                      name="shipping_method"
                      id="box"
                      checked={shippingMethod === "box"}
                      onChange={shippingCostChange}
                    />
                    <span>
                      <label htmlFor="box">Paczkomat</label>
                    </span>
                  </span>
                  <span className={`order__price ${Language}`}>19</span>
                </li>
                {shippingMethod === "box" && (
                  <div className="inpost__details">
                    {point && (
                      <div>
                        <p>Wybrany paczkomat:</p>
                        <p>
                          <strong>{point.name}</strong>
                        </p>
                        <p>
                          {point.street} {point.building_number} {point.flat_number}
                        </p>
                        <p>
                          {point.post_code} {point.city}
                        </p>
                        <button onClick={() => setPoint(null)}>Zmień paczkomat</button>
                      </div>
                    )}
                    {!point && (
                      <>
                        <p style={{ color: "red" }}>Wybierz swój paczkomat</p>
                        <InpostGeowidget
                          token={
                            "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJzQlpXVzFNZzVlQnpDYU1XU3JvTlBjRWFveFpXcW9Ua2FuZVB3X291LWxvIn0.eyJleHAiOjIwMDY1MzA1MzYsImlhdCI6MTY5MTE3MDUzNiwianRpIjoiN2IzZGNiOWYtMjFlNi00ZDg2LWI2MTgtMGNiMzQwMmZhMzlhIiwiaXNzIjoiaHR0cHM6Ly9sb2dpbi5pbnBvc3QucGwvYXV0aC9yZWFsbXMvZXh0ZXJuYWwiLCJzdWIiOiJmOjEyNDc1MDUxLTFjMDMtNGU1OS1iYTBjLTJiNDU2OTVlZjUzNTp6cnpwSURUcHZLdE5UX1NZNmVZSzdjVkVzMzhpN3Y5Ui14VzcxbDBaYk1BIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoic2hpcHgiLCJzZXNzaW9uX3N0YXRlIjoiODA3NjEwOTUtMjhlOC00ZGUyLWFmY2ItZWZiZDI5ZjFiZGFhIiwic2NvcGUiOiJvcGVuaWQgYXBpOmFwaXBvaW50cyIsInNpZCI6IjgwNzYxMDk1LTI4ZTgtNGRlMi1hZmNiLWVmYmQyOWYxYmRhYSIsImFsbG93ZWRfcmVmZXJyZXJzIjoiIiwidXVpZCI6Ijk4MzQ4Y2QyLWUyYmYtNDI2OC1iYzFjLTU3MDE0NTA1ZmU5ZCJ9.nvbAQozVPGnroOyjSPzytKddOpYNST5NqZk7fxmO8ToMizWddl7lUwKOZxizuKVpEbHYKahdaCcvIKnY1Rxgt-as-CbrHth_B3tmzcl4rA2Q3AtvG1xZKLZlxRC-w-0RDKI1iheplI1MzbwEN76V7FwKkxHyDEbImzhUFSrU6wNHSGw9gwtiq3JzbmA_oAxr_82wgwxeCDM4uBrRw2cwxEg49OxtcdOJHq9xYmdPbTPicaZNhgpDqtfi4FJNwmATGq8P4yoh5n0UCQzNCnk_zZPuJ4yhYYp0yji8ldy2GJp7UurTMfzqiAbINybqtdSXRw65GdD3Yu7KbfTnlQf09A"
                          }
                          onPoint={onPointSelect}
                        />
                      </>
                    )}
                  </div>
                )}
              </ul>
            </div>
            {/* order summary ------------------------------------------------------------ */}
            <div className="summary">
              <h3>Podsumowanie zamówienia</h3>
              <ul className="summary__list">
                {userCart ? (
                  userCart.map((item) => {
                    let price = 0;
                    if (userCountry !== "PL" && isCompany === true) {
                      price = parseFloat(item.netto);
                    } else {
                      price = parseFloat(item.brutto);
                    }
                    return (
                      <li
                        key={item.productId}
                        className="detail__item"
                      >
                        <span>
                          <strong>{item.quantity}</strong> x {item.name}
                        </span>
                        <span className={`order__price ${Language}`}>{((Math.round((price - price * discount) * 100) / 100) * item.quantity).toFixed(2)}</span>
                      </li>
                    );
                  })
                ) : (
                  <div></div>
                )}
                <li className="detail__item">
                  <span>
                    <span>Łączny koszt produktów</span>
                  </span>
                  <span className={`order__price ${Language}`}>{productsValue.toFixed(2)}</span>
                </li>
                <li className="detail__item">
                  <span>
                    <span>Koszt dostawy</span>
                  </span>
                  <span className={`order__price ${Language}`}>{shippingCost}</span>
                </li>
                <li className="detail__item sum">
                  <span>
                    <span>Łącznie do zapłaty</span>
                  </span>
                  <span className={`order__price ${Language}`}>{(orderValue ? orderValue : 0).toFixed(2)}</span>
                </li>
              </ul>
            </div>
            {/* payment methods ---------------------------------------------------------- */}
            <div className="payment">
              <h3>Płatności obsługuje PayU</h3>
              <div className="payments__img">
                <img
                  src=".\assets\payu_logo_lime.png"
                  alt="payu"
                />
                <img
                  src=".\assets\blik_logo_rgb.png"
                  alt="blik"
                />
              </div>
              {/* place order ----------------------------------------------------- */}
              <div className="input__line__checkbox">
                <input
                  type="checkbox"
                  id="terms"
                  {...register("terms")}
                />
                <label htmlFor="terms">Akceptuję regulamin zakupów (konieczne do złożenia zamówienia)</label>
              </div>
              <div className="input__line__checkbox">
                <span className="input__err error_select">{errors.terms?.message && "Pole jest wymagane"}</span>
              </div>
              <div className="input__line__checkbox">
                <input
                  type="checkbox"
                  id="data_processing"
                  {...register("data_processing")}
                />
                <label htmlFor="data_processing">Wyrażam zgodę na przetwarzanie podanych przeze mnie danych osobowych przez Sklep GenFactor.pl w celu realizacji zamówienia.</label>
              </div>
              <div className="input__line__checkbox">
                <span className="input__err error_select">{errors.data_processing?.message && "Pole jest wymagane"}</span>
              </div>
              {idle ? (
                <button
                  type="submit"
                  form="order_form"
                  className="btn idle order__btn"
                  disabled={idle}
                >
                  Prozę czekać...
                </button>
              ) : (
                <button
                  type="submit"
                  form="order_form"
                  className="btn order__btn"
                  disabled={!active}
                >
                  Zamawiam i płacę
                </button>
              )}
            </div>
          </div>
        </div>
        {/* <button className="btn order__btn" onClick={() => taxValidation()}>Zamawiam i płacę</button> */}
      </div>
    </>
  );
}
