const dev = false;

const apiUrl = dev ? "http://localhost:3001" : "https://genfactor-server.pl";

// online server: 'https://hrpdev.pl'
// local server: 'http://localhost:3001'

const bAdress = {
  email: "",
  repeatEmail: "",
  name: "",
  surname: "",
  companyName: "",
  taxNumber: "",
  street: "",
  addressNumber1: "",
  addressNumber2: "",
  city: "",
  zipCode: "",
  country: "",
  phone: "",
  isCompany: "",
};

const sAdress = {
  email: "",
  repeatEmail: "",
  name: "",
  surname: "",
  companyName: "",
  taxNumber: "",
  street: "",
  addressNumber1: "",
  addressNumber2: "",
  city: "",
  zipCode: "",
  country: "",
  phone: "",
  isCompany: "",
};

export { apiUrl, bAdress, sAdress };
