import { useEffect, useState } from "react";

import { useGetProducts } from "../hooks/useGetProducts";

import ProductCard from "./ProductCard";
import { useCart } from "../controllers/Cart";
import { useUser } from "../controllers/UserContext";
import GridSkleton from "./Skeletons/GridSkeleton";
import ErrorDisplay from "./Layout/ErrorDisplay";

function GridSelect({ categories }) {
  const userCart = useCart();

  const userObject = useUser();

  const { prods, err } = useGetProducts(categories);

  const [products, setProducts] = useState([]);
  const [isPennding, setIsPennding] = useState(true);

  useEffect(() => {
    let outputState = prods.sort((x, y) => {
      return x.seq - y.seq;
    });
    setProducts(outputState);
    if (products.length !== 0) {
      setIsPennding(false);
    }
  }, [prods, products]);

  return (
    <>
      {err && <ErrorDisplay />}
      <div className="grid">
        {isPennding && !err ? (
          <GridSkleton />
        ) : (
          products.map((product) => {
            const productInCart = userCart.find((item) => {
              return item.productId === product.id;
            });
            return (
              <div
                className="grid__item"
                key={product.id}
              >
                <ProductCard
                  product={product}
                  productInCart={productInCart}
                  type={userObject.user.userType}
                />
              </div>
            );
          })
        )}
      </div>
    </>
  );
}

export default GridSelect;
