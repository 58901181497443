import { NavLink } from "react-router-dom";

export default function Navigation({ location }) {
  return (
    <div className={`navigation ${location}`}>
      <ul className="navigation__list">
        <li key="1">
          <NavLink to="/">Produkty</NavLink>
        </li>
        <li key="2">
          <NavLink to="/colostrum">Colostrum</NavLink>
        </li>
        <li key="3">
          <NavLink to="/contact">Kontakt</NavLink>
        </li>
      </ul>
    </div>
  );
}
