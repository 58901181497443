import { useParams } from "react-router";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";

import { useGetProduct } from "../../hooks/useGetProduct";
import { useDispatchCart } from "../../controllers/Cart";
import { useCart } from "../../controllers/Cart";
import { useUser } from "../../controllers/UserContext";
import { useLanguage } from "../../controllers/Language";

// import Rating from '../../components/Rating'
import Quantity from "../../components/Quantity";

export default function Product() {
  const productId = useParams();
  const id = parseInt(productId.id);

  const Language = useLanguage();

  const dispatch = useDispatchCart();

  const { prod: p, isPennding, err } = useGetProduct(id);

  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [type, setType] = useState();
  const [brutto, setBrutto] = useState(0);
  const [netto, setNetto] = useState(0);

  const userCart = useCart();

  const userObject = useUser();

  const productInCart = userCart.find((item) => {
    return item.productId === id;
  });

  useEffect(() => {
    if (userObject && userObject.user) {
      setType(userObject.user.userType);
    }
  }, [userObject]);

  useEffect(() => {
    setProduct(p);
  }, [p]);

  useEffect(() => {
    if (product) {
      if (product.category === "Personal Care" || product.id === 139) {
        switch (type) {
          case "partner30":
            setBrutto(product.brutto_a);
            setNetto(product.netto_a);
            break;
          case "client":
            setBrutto(product.brutto_c);
            setNetto(product.netto_c);
            break;
          default:
            setBrutto(product.brutto);
            setNetto(product.netto);
            break;
        }
      } else {
        switch (type) {
          case "partner30":
            setBrutto(product.brutto_a);
            setNetto(product.netto_a);
            break;
          default:
            setBrutto(product.brutto);
            setNetto(product.netto);
            break;
        }
      }
    }
  }, [product, type]);

  const handleQuantityChange = (direction) => {
    if (direction === "minus") {
      setQuantity((prevQuantity) => prevQuantity - 1);
    } else if (direction === "plus") {
      setQuantity((prevQuantity) => prevQuantity + 1);
    }
  };

  useEffect(() => {
    if (product) {
      setTotalPrice(parseFloat(brutto) * quantity);
    } else {
      setTotalPrice(0);
    }
  }, [product, quantity, brutto]);

  let cartPayload;

  if (product) {
    cartPayload = {
      productId: product.id,
      name: product.name,
      short_name: product.short_name,
      brutto: brutto,
      netto: netto,
      vat: product.vat,
      quantity: quantity,
      ref_number: product.ref_number,
      weight: product.weight,
    };
  }

  const addToCart = (item) => {
    dispatch({ type: "ADD", item });
  };

  useEffect(() => {
    dispatch({ type: "READ" });
  }, [dispatch]);

  const variants = {
    active: {
      scale: 0.95,
      rotate: -3,
      transition: {
        duration: 0.9,
        type: "spring",
        stiffness: 100,
      },
    },
  };

  return (
    <>
      <>
        {err ? (
          <div className="error_msg">{err}</div>
        ) : !product || isPennding ? (
          <h2>Ładowanie...</h2>
        ) : (
          <div className="mt4">
            <div className="single__product">
              <div className="single__img">
                <motion.div
                  className="product__img"
                  style={{ backgroundImage: `url('${product.main_img}')` }}
                  whileHover="active"
                  variants={variants}
                ></motion.div>
              </div>
              <div className="single__info">
                <h2 className="single__name">{product.name}</h2>
                {!product.brutto ? <h3>...</h3> : <h2 className={`single__product__price ${Language}`}>{totalPrice}</h2>}

                {/* <div className='single__ratings'>
                <Rating rating={product.rating}/>
              </div> */}

                <p className="single__category">{product.category}</p>
                <p>{product.description}</p>
                <Quantity handleQuantityChange={handleQuantityChange}>{quantity}</Quantity>
                {!productInCart ? (
                  <button
                    onClick={() => addToCart(cartPayload)}
                    className="btn-alert w100"
                  >
                    Dodaj do Koszyka
                  </button>
                ) : (
                  <NavLink
                    to={`/koszyk`}
                    className="w100"
                  >
                    <button className="btn-alert w100">Zobacz koszyk</button>
                  </NavLink>
                )}
                <br />
                <NavLink
                  to={`/`}
                  className="w100"
                >
                  <button className="btn-alert w100 marginTop">Wszystkie produkty</button>
                </NavLink>
              </div>
            </div>
            <br />
            <h3 className="mt4 details_heading">Szczegóły:</h3>
            <div
              className="single__description"
              dangerouslySetInnerHTML={{ __html: product.full_description }}
            />
          </div>
        )}
      </>
    </>
  );
}
