//basic
import { useState, useEffect } from 'react'
// import { NavLink } from "react-router-dom"
import { useNavigate } from "react-router"
import Axios from "axios"

import { apiUrl } from "../../Vars";

import CartItem from "../../components/CartItem"

import { useCart } from '../../controllers/Cart'
import { useDispatchCart } from '../../controllers/Cart'
import { useUser } from '../../controllers/UserContext';
import { useLanguage } from '../../controllers/Language';


export default function Koszyk() {

  const navigate = useNavigate()

  const userCart = useCart()
  const readCart = useDispatchCart()
  const dispatch = useDispatchCart()

  const userObject = useUser()

  const Language = useLanguage()

  const [ cartValue, setCartValue ] = useState()
  const [ vatValue, setVatValue ] = useState()
  const [ discount ] = useState()
  const [ activeDiscount, setActiveDiscount ] =useState(false)
  const [ discountCartValue, setDiscountCartValue ] = useState(0)
  const [ discountValue, setDiscountValue ] = useState(0)


  useEffect(() => {
    if (userCart) {
      let value = 0;
      for (let i = 0; i < userCart.length; i++) {
        value = value + (parseFloat(userCart[i].brutto) * userCart[i].quantity)
      }
      setCartValue(value)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCart])

  useEffect(() => {
    if (userCart) {
      let value = 0;
      for (let i = 0; i < userCart.length; i++) {
        value = value + (parseFloat(userCart[i].brutto) * (userCart[i].vat - 1) * userCart[i].quantity)
      }
      setVatValue(Math.round(value * 100) / 100)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCart])

  useEffect(() => {
    readCart({ type: "READ"})
  }, [readCart])


  // eslint-disable-next-line no-unused-vars
  const applyDiscount = () => {
    Axios.post(`${apiUrl}/api/discount-validate`, {
      discount: discount,
      email: userObject.user.email
    })
      .then(res => {
        if (res.data.message) {
          alert(res.data.message)
        } else {
          const discountObject = res.data
          setDiscountValue(discountObject.value)
          setActiveDiscount(true)
          sessionStorage.setItem("discount", JSON.stringify(discountObject))
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    const percentage = parseInt(discountValue) / 100
    setDiscountCartValue(Math.round((cartValue - (cartValue * percentage)) * 100) / 100)
  }, [discountValue, cartValue])

  useEffect(() => {
    setVatValue((Math.round((discountCartValue - (discountCartValue / 1.23)) * 100)) / 100)
  }, [discountCartValue])

  // const disc = {
  //   "name": "kod03",
  //   "type": "value",
  //   "value": "35",
  //   "uses_count": "10",
  //   "for_users": "unlimited",
  //   "require_product": "null",
  //   "cart_value": "unlimited",
  //   "effect": "cart",
  //   "expire": "2023-12-01"
  // }

  const goToOrder = () => {
    Axios.post(`${apiUrl}/api/cart-create`, {
      userCart
    })
      .then(res => {
        if (res.data.message === 'Out of stock') {
          alert('Jednego Twoich produktów nie ma już na stanie')
          const prodOutOfStock = res.data.products
          prodOutOfStock.forEach(i => {
            const item = i.prodId
            dispatch({ type: "DELETE", item })
          });
          
        } else {
          navigate(`/zamowienie?cart_id=${res.data}`)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <>
      <h2 className="title">Koszyk</h2>
      <>
        {!userCart ? 
        <h3>Ładowanie...</h3> :
        userCart.length === 0 ? 
        <h3 className='alert'>Nie masz jeszcze w koszyku żadnych produktów</h3> :
        <div className='cart'>
          <div className='cart__items'>
            {userCart.map(cartProduct => 
              (<CartItem key={cartProduct.productId} cartProduct={cartProduct} type={userObject.user.userType} />
            ))}
          </div>
          <div className="cart__summary">
            <div className='cart__details'>
              <h3 className='cart__item__qty'>Suma: </h3>
              <h3 className={`cart__item__price ${Language}`}>{cartValue}</h3>
              <div className="cart__item__icon"></div> 
            </div>
            {activeDiscount ? <div className='cart__details'>
              <h3 className='cart__item__qty'>Kwota po rabacie: </h3>
              <h3 className={`cart__item__price ${Language}`}>{discountCartValue.toFixed(2)}</h3>
              <div className="cart__item__icon"></div> 
            </div> : <></> }
            <div className='cart__details'>
              <h4 className=''>w tym: </h4>
              <h4 className=''>VAT: <span className={`${Language}`}>{vatValue}</span></h4>
              <div className="cart__item__icon"></div> 
            </div>
            {/* {activeDiscount ? <></> : 
            <div className='cart__discount'>
              <h4 className=''>Kod rabatowy:</h4>
              <form action="/discount">
                <input type="text" name='discount' onChange={(e) => setDiscount(e.target.value)}/>
              </form>
              <button className='btn discount-btn' onClick={() => applyDiscount()}>Zastosuj kod</button>
            </div>} */}
            <button className='btn' onClick={() => goToOrder()}>Przejdź do zamówienia</button>
          </div>
        </div>
        }
      </>
    </>
  )
}
 